<script setup>

</script>

<template>
  <div class="container-fluid">
    <nav class="row navbar navbar-dark navbar-expand-lg">
      <div class="container">
        <a href="#" class="navbar-brand">HDR Haus und Teich Reichardt</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#">Startseite</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#angebote">Leistungen</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#aboutus">Über uns</a>
            </li>
            <li class="nav-item">
              <a class="nav-link"  href="#partner">Partner</a>
            </li>
            <li class="nav-item">
              <a class="nav-link"  href="#contact">Kontakt</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<style scoped>
.navbar {
  color: white;
  background: rgb(17, 26, 40);
}
.container-fluid{
  z-index: 2000;
}
</style>