<script setup>

</script>

<template>
  <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#datenSchutzmodal">
    Datenschutz
  </button>
</template>

<style scoped>

</style>