<script setup>

</script>

<template>
  <div class="container-fluid" id="aboutus">
    <div class="row py-5  align-items-center">
      <div class="col-12 col-md-6">
        <img src="@/assets/history.jpg" alt="" class="img-thumbnail rounded float-end">
      </div>
      <div class="col-12 col-md-6 container">
        <div class="row align-items-center mt-3 mt-md-0">
          <h2 class="h2">Über uns</h2>

          <p>HDR Haus und Teich ist ein Dienstleistungsbetrieb mit Bezug auf die Baubranche. Neben langjähriger
            Erfahrung im Teichbau, im speziellen Koi- und Schwimmteiche, bieten wir ein vielschichtiges
            Arbeitsspektrum
            rund um Ihre Immobilie an. Wir leben mit dem Bewusstsein das sich die Dienstleistungsbranche in der
            heutigen
            Zeit zwar immer größerem Erfolg erfreut, jedoch für viele das Vertrauen auf der Strecke bleibt. Und genau
            aus diesen Gründen hebt sich HDR von der breiten Masse ab. Wir sind, auf den Punkt gebracht,
            vertrauensvoll,
            innovativ und vielseitig. Wir vereinen alte, traditionelle Tugenden mit neuen und frischen Innovationen
            und
            schaffen damit einen erheblichen Mehrwert für Kunden, denen die lästige Suche nach dem Handwerker des
            Vertrauens, ein Graus ist.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container-fluid {
  color: white;
  background-color: rgb(17, 26, 40);
  filter: brightness(160%);
  padding-bottom: 50px;
}

p {
  font-size: 20px;
  margin: 0 auto 0 auto;
}
</style>