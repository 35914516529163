<script setup>

</script>

<template>
  <div class="container-fluid">
      <div id="headimage" class="row text-center">
        <div class="col">
          <div class="row justify-content-center">
            <div class="col col-lg-10">
              <h1 class="h1">HDR Haus und Teich Reichardt</h1>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col col-lg-10">
              <h4 class="h4">Ihr Experte für herausragendes Landschaftsdesign und Innenrenovierungen in Kaiserslautern.</h4>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<style scoped>
.col-lg-10{
  background: rgba(0,0,0, 0.5);
}
.h1,h4 {
  color: white;
}

#headimage {
  background-clip: padding-box;
  background-image: url('@/assets/headimage.jpg');
  overflow: visible;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-attachment: scroll;
  padding: 100px 32px 290px 32px;
}
</style>