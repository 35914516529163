<script setup>
const partner = [
  {
    name: "Abwassertechnik Weber",
    img: "/partner/weber.png"
  },
  {
    name: "Bertram und Leist",
    img: "/partner/bertram+leist.png"
  },
  {
    name: "Fressnapf XXL",
    img: "/partner/fressnapf.png"
  },
  {
    name: "Peschel Gruppe",
    img: "/partner/peschel-gruppe.png"
  },
  {
    name: "Hornbach Pirmasens",
    img: "/partner/hornbach.png"
  },
  {
    name: "Hornbach Saarbrücken",
    img: "/partner/hornbach.png"
  }
]
</script>

<template>
  <div class="container-fluid" id="partner">
    <div class="row py-5  align-items-center">
      <div class="col-12 col-md-8">
        <div class="row align-items-center">
          <h2 class="h2">Mit herausragenden Partnern an Ihrer Seite</h2>
          <p>Ausgezeichnet zum Hornbach top Service Partner 2021.</p>
        </div>
        <div class="row g-3 row-cols-2 row-cols-md-3 row-cols-md-6 mt-5">
          <div class="col" v-for="p of partner" v-bind:key="p">
            <div class="card h-100">
              <img :src="p.img" class="card-img-top" :alt="'Logo von '+p.name">
              <div class="card-footer mt-auto">
                <h5 class="text-body-secondary">{{ p.name }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mt-3 mt-md-0">
        <img src="@/assets/partner/top-service-partner.png" alt="" class="img-thumbnail rounded float-end">
      </div>

    </div>
  </div>
</template>

<style scoped>
.container-fluid {
  color: white;
  background-color: rgb(17, 26, 40);
  padding-bottom: 50px;
}

p {
  font-size: 20px;
  margin: 0 auto 0 auto;
}
.text-body-secondary{
 font-size: 14px;
}
</style>