<script setup>
import {ref, onMounted} from "vue";
import VanillaTilt from "vanilla-tilt";

const element = ref(null)

onMounted(() => {
  VanillaTilt.init(document.querySelectorAll('[data-tilt]'), {max: 20, speed: 200})
})
const services = ["Zaunbau", "Terrassenbau", "Carports", "Gartenhäuser", "Raumausstattungen", "Fenster und Türen", "Bodenbeläge", "und vieles Mehr"];
</script>

<template>
  <div class="container-fluid" id="angebote">
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col-12"><h1 class="h1 pt-5 text-center">Qualität durch Meisterkennung</h1></div>
          <div class="col-12"><h3 class="h3 pb-5 text-center">Professionell, Zuverlässig und Individuell!<br/> Als
            zertifizierter Meisterbetrieb machen wir im Umkreis Kaiserslautern nahezu alles, wo andere sich nicht ran
            trauen.</h3></div>
        </div>
        <div class="row g-4 g-md-3 g-lg-3" ref="element">
          <div class="col-12 col-md-6 col-lg-3" v-for="service in services" v-bind:key="service" data-tilt>
            <div class="colored">
            {{ service }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container-fluid {
  color: white;
  background-color: rgb(17, 26, 40);
  padding-bottom: 50px;
}
[data-tilt]{
  text-align: center;
  font-weight: bolder;

}
.colored{
  font-size: 20px;
  line-height: 120px;
  height: 120px;
  background: #283048;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #859398, #283048);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #859398, #283048); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>